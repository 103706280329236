import { useNavigate, useSearchParams } from '@remix-run/react';
import truncate from 'lodash/truncate';
import { useMemo, useState } from 'react';
import { type KeyedMutator } from 'swr';

import {
  type DtoPromptTemplate,
  EnumsPromptTemplateType,
} from '@lp-lib/api-service-client/public';

import { ActionSheet } from '../components/ActionSheet';
import { type Action } from '../components/ActionSheet';
import { useAwaitFullScreenConfirmCancelModal } from '../components/ConfirmCancelModalContext';
import { ModalWrapper } from '../components/ConfirmCancelModalContext/ModalWrapper';
import { CopyIcon } from '../components/icons/CopyIcon';
import { DeleteIcon } from '../components/icons/DeleteIcon';
import { LockIcon } from '../components/icons/LockIcon';
import { usePromptTemplates } from '../components/PromptTemplate';
import { PromptTemplateUtils } from '../components/PromptTemplate/utils';
import { useUser } from '../components/UserContext';
import { useLiveAsyncCall } from '../hooks/useAsyncCall';
import { useInstance } from '../hooks/useInstance';
import { useTitle } from '../hooks/useTitle';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';
import { apiService } from '../services/api-service';
import { makeTitle } from '../utils/common';
import { DateUtils } from '../utils/date';

type ActionSheetKeys = 'clone' | 'lock' | 'delete';

function PromptTemplateItem(props: {
  template: DtoPromptTemplate;
  mutate: KeyedMutator<DtoPromptTemplate[]>;
}): JSX.Element {
  const { template, mutate } = props;
  const navigate = useNavigate();
  const confirmCancel = useAwaitFullScreenConfirmCancelModal();

  const handleRowClick = (e: React.MouseEvent) => {
    const target = `/admin/prompt-templates/${template.id}`;

    if (e.metaKey || e.ctrlKey) {
      // We can't use a `Link` because it's actually a table row :(
      window.open(target, '_blank', 'noopener,noreferrer');
    } else {
      navigate(target);
    }
  };

  const {
    state: { state },
    call: onDelete,
  } = useLiveAsyncCall(async () => {
    const response = await confirmCancel({
      kind: 'confirm-cancel',
      prompt: (
        <div className='text-white text-center text-2xl font-medium'>
          <div className='flex flex-col gap-1'>
            <div>Are you sure you want to delete this prompt template?</div>
            <div className='text-red-002'>
              Anything used this template will be broken!!!
            </div>
          </div>
        </div>
      ),
      confirmBtnLabel: 'Delete',
      cancelBtnLabel: 'Cancel',
      autoFocus: 'cancel',
      confirmBtnVariant: 'delete',
    });
    if (response.result !== 'confirmed') return;
    await apiService.promptTemplate.deleteTemplate(template.id);
    mutate();
  });

  const { call: onClone } = useLiveAsyncCall(async () => {
    await apiService.promptTemplate.cloneTemplate(template.id);
    mutate();
  });

  const { call: onLock } = useLiveAsyncCall(async () => {
    await apiService.promptTemplate.lockTemplate(template.id);
    mutate();
  });

  const actions: Action<ActionSheetKeys>[] = [
    {
      kind: 'button',
      key: 'clone',
      icon: <CopyIcon />,
      text: 'Clone Template',
      onClick: onClone,
    },
  ];

  if (!PromptTemplateUtils.OwnedBySystem(template)) {
    actions.push({
      kind: 'button',
      key: 'lock',
      icon: <LockIcon />,
      text: 'Lock as System Template',
      onClick: onLock,
    });
  }

  if (PromptTemplateUtils.Editable(template)) {
    actions.push({
      kind: 'button',
      key: 'delete',
      icon: <DeleteIcon />,
      text: 'Delete Template',
      className: 'text-red-002',
      disabled: state.isRunning,
      onClick: onDelete,
    });
  }

  return (
    <tr
      className='text-sms cursor-pointer hover:bg-lp-gray-002 odd:bg-lp-gray-001'
      onClick={handleRowClick}
    >
      <td className='py-1.5'>{template.name}</td>
      <td
        className={`py-1.5 ${
          PromptTemplateUtils.OwnedBySystem(template)
            ? 'text-tertiary'
            : 'text-white'
        }`}
      >
        {PromptTemplateUtils.OwnedBySystem(template)
          ? 'System'
          : template.owner?.username || 'Unknown'}
      </td>
      <td className='py-1.5'>
        {template.type === EnumsPromptTemplateType.PromptTemplateTypeSnippet ? (
          <p>{template.type}</p>
        ) : (
          <p>
            {template.provider}/{template.model}/{template.type}
          </p>
        )}
        {template.vendorObjectId && <p>({template.vendorObjectId})</p>}
      </td>
      <td className='py-1.5'>
        {truncate(template.systemPrompt, { length: 50 })}
      </td>
      <td className='py-1.5'>
        {template.functions?.length
          ? template.functions.map((f, i) => <p key={i}>{f.name}</p>)
          : 'N/A'}
      </td>
      <td className='py-1.5'>{DateUtils.FormatDatetime(template.updatedAt)}</td>
      <td className='py-1.5' onClick={(e) => e.stopPropagation()}>
        <ActionSheet<ActionSheetKeys> actions={actions} placement='left' />
      </td>
    </tr>
  );
}

type Group = 'my' | 'system' | 'others';
type GroupedTemplates = { [key in Group]: DtoPromptTemplate[] };

function GroupTab(props: {
  label: string;
  onClick: () => void;
  active: boolean;
}) {
  return (
    <button
      type='button'
      className={`btn rounded-none w-40 h-12 bg-black border ${
        props.active ? 'border-white' : 'border-secondary'
      }`}
      onClick={props.onClick}
    >
      {props.label}
    </button>
  );
}

function ChoosePromptTemplateTypeModal(props: {
  onCancel: () => void;
  onConfirm: (type: EnumsPromptTemplateType) => void;
}) {
  const [type, setType] = useState<EnumsPromptTemplateType>(
    EnumsPromptTemplateType.PromptTemplateTypeSimple
  );
  const typeOptions = useInstance(() => PromptTemplateUtils.TypeOptions());

  return (
    <ModalWrapper
      containerClassName='w-120'
      borderStyle='gray'
      onClose={props.onCancel}
    >
      <div className='w-full flex flex-col items-center justify-center gap-6 p-6'>
        <div className='text-2xl font-medium text-center'>
          Which type of prompt template would you like to create?
        </div>
        <div className='flex flex-col gap-2'>
          {typeOptions.map((o) => (
            <label
              className={`border ${
                type === o.value ? ' border-white' : ' border-secondary'
              } rounded-xl p-2 flex flex-col gap-1 cursor-pointer`}
              key={o.value}
            >
              <div className='flex items-center gap-1'>
                <input
                  type='radio'
                  name='type'
                  className='field-radio'
                  checked={type === o.value}
                  onChange={() => setType(o.value)}
                />
                <span>{o.label}</span>
              </div>
              <p className='text-sms text-secondary'>{o.desc}</p>
            </label>
          ))}
        </div>
        <div className='flex items-center justify-center gap-4'>
          <button
            type='button'
            className='w-33 h-10 btn-secondary items-center justify-center'
            onClick={() => props.onCancel()}
          >
            Cancel
          </button>
          <button
            type='button'
            className='w-33 h-10 btn-primary flex items-center justify-center'
            onClick={() => props.onConfirm(type)}
          >
            Create
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}

function PromptTemplateList(): JSX.Element {
  const { data: templates, mutate } = usePromptTemplates(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const user = useUser();
  const groupedTemplates = useMemo(() => {
    const groups: GroupedTemplates = {
      my: [],
      system: [],
      others: [],
    };
    if (!templates) return groups;
    for (const t of templates) {
      if (PromptTemplateUtils.OwnedBySystem(t)) {
        groups.system.push(t);
      } else if (t.uid === user?.id) {
        groups.my.push(t);
      } else {
        groups.others.push(t);
      }
    }
    return groups;
  }, [templates, user?.id]);

  useTitle(makeTitle('Prompt Templates'));

  const activeGroup = (searchParams.get('tab') as Group) || 'my';
  const handleGroupChange = (newGroup: Group) => {
    navigate(`?tab=${newGroup}`);
  };

  const triggerModel = useAwaitFullScreenConfirmCancelModal();
  const openChooseTypeModal = () => {
    triggerModel({
      kind: 'custom',
      element: (p) => (
        <ChoosePromptTemplateTypeModal
          onCancel={p.internalOnCancel}
          onConfirm={(type) => {
            p.internalOnConfirm();
            navigate(`./create?type=${type}`);
          }}
        />
      ),
    });
  };

  return (
    <div className='flex flex-col gap-6 text-white'>
      <div className='flex items-center justify-between'>
        <p className='text-2xl font-medium'>Prompt Templates</p>
        <button
          type='button'
          className='btn-primary w-42 h-10 flex justify-center items-center'
          onClick={() => openChooseTypeModal()}
        >
          Create Template
        </button>
      </div>
      <div className='flex items-center'>
        <GroupTab
          label='My'
          onClick={() => handleGroupChange('my')}
          active={activeGroup === 'my'}
        />
        <GroupTab
          label='System'
          onClick={() => handleGroupChange('system')}
          active={activeGroup === 'system'}
        />
        <GroupTab
          label='Others'
          onClick={() => handleGroupChange('others')}
          active={activeGroup === 'others'}
        />
      </div>
      <table className='w-full'>
        <thead>
          <tr className='text-left'>
            <th className='pb-3'>Name</th>
            <th className='pb-3'>Owned By</th>
            <th className='pb-3'>Provider/Model/Type</th>
            <th className='pb-3'>System Prompt</th>
            <th className='pb-3'>Functions</th>
            <th className='pb-3'>Updated At</th>
            <th className='pb-3'></th>
          </tr>
        </thead>
        <tbody>
          {groupedTemplates[activeGroup]?.map((t) => (
            <PromptTemplateItem key={t.id} template={t} mutate={mutate} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export function Component() {
  return (
    <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
      <AdminToolkitNav />

      <PromptTemplateList />
    </AdminView>
  );
}
